
export const addOrRemove = (array, value) => {
  const updated = [...array] // do not mutate
  const index = array.indexOf(value)
  if (index === -1) {
    updated.push(value)
  } else {
    updated.splice(index, 1)
  }
  return updated
}

const uniq = (arr)=> {
  return [...new Set(arr)]
}

const replace = (arr, index, value) => {
  return Object.assign([], arr, {[index]: value})
}

const remove = (arr, index) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

// n: each n items
const toMatrix = (arr, n) => {
  let matrix = [], i, k
  for (i = 0, k = -1; i < arr.length; i++) {
    if (i % n === 0) {
      k++
      matrix[k] = []
    }
    matrix[k].push(arr[i])
  }
  return matrix
}

// params may convert array to string, so convert is back as array if it is a string
const maybeArray = (arrayOrString) => {
  if(typeof arrayOrString === 'string'){
    return arrayOrString.split(',')
  }else{
    return arrayOrString
  }
}

const max = (array) => {
  const filtered = array.filter(n => typeof n === 'number')
  return filtered.length ? Math.max(...filtered) : null
}

// convert array ['a', 'b', 'c'] => { a: null, b: null, c: null }
const toMap = (array=[]) => {
  const object = {}
  array.forEach(key => object[key] = null)
  return object
}

export const array = {
  uniq,
  addOrRemove,
  replace,
  remove,
  toMatrix,
  maybeArray,
  max,
  toMap,
}

