
import { requests } from '@/features/requests'
import pluralize from 'pluralize'
import api from '@/api'
import { remove, set as setEntity, schemas } from '@/features/entities'
import {
  set as setErrors,
  clear as clearErrors,
} from '@/features/errors'
import { capture } from '@/sentry'
import RootNavigation from '@/navigation/RootNavigation'
import { routes } from '@/features/routes'
import { refreshToken } from '@/features/auth'
import { flash } from '@/features/flash'
import { t } from '@/i18n'
import { requestReview } from '@/features/home'

// requestType: create | read | update | destroy
const httpTypes = {
  create: 'post',
  read: 'get',
  update: 'put',
  destroy: 'destroy',
  index: 'get',
  toggle: 'post',
}

const shouldModifyKey = {
  updateNotificationSetting: true,
  currentUser: true,
}

const modifier = {
  updateNotificationSetting: {
    targetKey: 'notificationSetting',
    targetPayload: 'updateNotificationSetting'
  },
  // DEPRECATED: do not use modifier, use alias instead
  currentUser: {
    targetKey: 'user',
    targetPayload: 'currentUser'
  },
}

export const storeResponseEntity = ({ payload }) => {
  return (dispatch, getState)=> {
    p('storeResponseEntity')

    if(!payload) return

    // loop through parent keys and store it
    Object.keys(payload).forEach(key => {

      // update token if there is one.
      // it will be returned when the user has updated their email for instance.
      if(key === 'token') {
        if(!payload[key]) return

        refreshToken({ token: payload[key] })(dispatch, getState)
        return
      }

      // FIXME: how should be handle these non-sync values? maybe use outside sync but requests.graphql
      if(key === 'page') return
      if(key === 'badge') return

      let targetKey = key
      let targetPayload = payload
      if(shouldModifyKey[key]){
        targetKey = modifier[key].targetKey
        targetPayload = payload[modifier[key].targetPayload]
      }
      // bookings -> booking
      const singular = pluralize.singular(targetKey)
      const schema = schemas[singular]

      const data = targetPayload[targetKey]
      if(Array.isArray(data)){
        setEntity(data, [schema])(dispatch)
      }else{
        setEntity(data, schema)(dispatch)
      }
    })
  }
}

// directly upload to s3 if the given image object include base64.
// otherwise it will be send to api and the api will fetch the uri and attach to a file.
const upload = (image)=> {
  return async(dispatch, getState) => {
    if(!image.base64){
      return image // { :uri }
    }
    // get s3 upload signed url
    const sResponse = await requests.get(api.images.s3PresignedUrl())(dispatch, getState)
    if(!sResponse.ok) throw 's3 presign error'
    const signature = await sResponse.json()
    const { url: signedUrl, public_url } = signature

    // get blob for this image
    const response = await fetch(image.uri)
    // do not check response.ok on this fetch, since it failed on android somehow.
    const blob = await response.blob()

    // uplaod to s3
    const uResponse = await fetch(signedUrl, { method: 'PUT', body: blob })
    if(!uResponse.ok) throw 'upload s3 error'

    return { uri: public_url }
  }
}



export const handleRequest = ({
  id, entity, requestType, payload, options,
}) => {
  p('sync/handleRequest', { requestType, id, entity, payload })
  return async(dispatch, getState) => {
    if(!dispatch || ! getState){
      ppError('You need to pass dispatch, getState to its function')
      return
    }

    try{

      let filteredPayload = payload

      if(entity === 'good' && payload?.images){

        const promises = []
        payload.images.map(img => {
          promises.push(upload(img)(dispatch, getState))
        })
        const images = await Promise.all(promises)

        filteredPayload = {
          ...payload,
          images,
        }
      }

      // it costructs put(Api.uri.trips.update(id), payload)
      const response = await requests[httpTypes[requestType]](
        api[pluralize(entity)][requestType](id),
        filteredPayload
      )(dispatch, getState)

      if(!response || !response.clone) return

      const cloned = response.clone()
      const json = await cloned.json()
      p('response.json', json)

      if(response?.ok && !json?.errors) {

        if(requestType === 'destroy'){
          remove(id, pluralize(entity))(dispatch)
        }else{
          storeResponseEntity({ payload: json })(dispatch, getState)
        }

        if(options?.onSuccess){
          ppWarn('DEPRECATED: options.onSuccess use returned response instead')
          options.onSuccess(json)
        }

      }else{
        dispatch(setErrors(json.errors))
        if(options?.onFailure){
          ppWarn('DEPRECATED: options.onFailure use returned response instead')
          options.onFailure(json)
        }
      }
      return response
    }catch(err){
      ppError(err)
      capture(err)
    }
  }
}

const setCreating = (entity, bool) => {
  return {
    type: types.setCreating,
    creating: { [entity]: bool }
  }
}

const setUpdating = (entity, bool) => {
  return {
    type: types.setUpdating,
    updating: { [entity]: bool }
  }
}

export const setLoading = (key, bool) => {
  return {
    type: types.setLoading,
    loading: { [key]: bool }
  }
}

export const create = ({
  entity, payload, options={}
})=> {
  return async(dispatch, getState) => {

    // navigate to sign in if it's required to be logged in
    if(entity !== 'user' && !getState().auth?.token){
      routes.setGoBackToCurrent()(dispatch)
      RootNavigation.navigate('SignIn')
      flash.add({
        type: 'error',
        body: t('flash.loginRequired')
      })(dispatch)
      ppError('You need to sign in')
      return
    }

    dispatch(setCreating(entity, true))
    dispatch(clearErrors())

    const response = await handleRequest({
      requestType: 'create',
      entity,
      payload,
      options,
    })(dispatch, getState)

    // requestReview will check if the user should be asked to review
    // (nps >= 8 && never asked)
    if(entity === 'booking'){
      await requestReview()(dispatch, getState)
    }

    dispatch(setCreating(entity, false))
    return response
  }
}

export const read = ({
  entity, id, params={}, options={}
})=> {
  return async(dispatch, getState) => {

    if(!id) {
      ppError(`sync/read id is ${id}`)
      return { ok: false }
    }

    return handleRequest({
      requestType: 'read',
      id,
      entity,
      // paylod will be passed as params and become a query
      payload: params,
      options,
    })(dispatch, getState)
  }
}

export const index = ({
  entity, params={}, options={}
})=> {
  return async(dispatch, getState) => {
    p('features/sync#index')
    return handleRequest({
      requestType: 'index',
      entity,
      // paylod will be passed as params and become a query
      payload: params,
      options,
    })(dispatch, getState)
  }
}

export const update = ({
  id, entity, payload, options={}
})=> {
  return async(dispatch, getState) => {
    if(!id) {
      ppWarn(`sync/update id is ${id}`)
      return { ok: false }
    }

    dispatch(setUpdating(entity, true))
    dispatch(clearErrors())

    const response = await handleRequest({
      requestType: 'update',
      id,
      entity,
      payload,
      options,
    })(dispatch, getState)

    dispatch(setUpdating(entity, false))
    return response
  }
}

const destroy = ({
  id, entity, options={}
}) => {
  return async(dispatch, getState) => {

    const response = await handleRequest({
      requestType: 'destroy',
      id,
      entity,
      options,
    })(dispatch, getState)

    return response
  }
}

const toggle = ({
  entity, payload
})=> {
  return async(dispatch, getState) => {

    dispatch(setCreating(entity, true))

    const response = await handleRequest({
      requestType: 'toggle',
      entity,
      payload,
    })(dispatch, getState)

    dispatch(setCreating(entity, false))
    return response
  }
}

// we might need a better solution in the future
// https://gist.github.com/rybon/7cafd12a635fd3a2bf720cefe9292f6c
const compressGraphqlDocument = graphqlDocument =>
  graphqlDocument
    .replace(/#.*\n/g, '')
    .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
    .replace(/:\s/g, ':')
    .replace(/,\s/g, ',')
    .replace(/\)\s\{/g, '){')
    .replace(/\}\s/g, '}')
    .replace(/\{\s/g, '{')
    .replace(/\s\}/g, '}')
    .replace(/\s\{/g, '{')
    .replace(/\)\s/g, ')')
    .replace(/\(\s/g, '(')
    .replace(/\s\)/g, ')')
    .replace(/\s\(/g, '(')
    .replace(/=\s/g, '=')
    .replace(/\s=/g, '=')
    .replace(/@\s/g, '@')
    .replace(/\s@/g, '@')
    .replace(/\s\$/g, '$')
    .replace(/\s\./g, '.')
    .trim()

const graphql = ({
  query,
  params={}
}) => {
  return async(dispatch, getState) => {
    p('sync#graphql')
    try{
      const q = compressGraphqlDocument(query)
      const response = await requests.post(
        api.graphql(),
        { query: q, ...params }
      )(dispatch, getState)
      if(!response?.ok) return false

      const json = await response.json()
      if(json.errors?.length > 0){
        const body = json.errors[0].message
        flash.add({ type: 'error', body })(dispatch)
        const e = new Error('graphql Error')
        e.name = body
      }else{
        storeResponseEntity({
          payload: json['data']
        })(dispatch, getState)
      }
      return json
    }catch(err){
      ppError(err, query)
      capture(err)
    }
  }
}

export const sync = {
  create,
  read,
  update,
  index,
  destroy,
  graphql,
  toggle,
}

const types = {
  setCreating: 'sync/setCreating',
  setUpdating: 'sync/setUpdating',
  setLoading: 'sync/setLoading',
  clear: 'sync/clear',
}

const initialState = {
  creating: {
    booking: false,
    folder: false,
    good: false,
    group: false,
    membership: false,
    message: false,
    user: false,
    post: false,
    usersManager: false,
    like: false,
    authToken: false,
    review: false,
    comment: false,
  },
  reading: {},
  updating: {
    booking: false,
    good: false,
    group: false,
    user: false,
    post: false,
    authToken: false,
    review: false,
  },
  loading: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setCreating:
      return{
        ...state,
        creating: action.creating
      }
    case types.setUpdating:
      return{
        ...state,
        updating: action.updating
      }
    case types.setLoading:
      return{
        ...state,
        loading: action.loading
      }
    case types.clear:
      return initialState
    default:
      return state
  }
}
