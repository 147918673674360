import React from 'react'
import { PropTypes } from 'prop-types'
import Link from 'components/Link'
import TouchableOpacity from 'components/TouchableOpacity'
import { View, StyleSheet } from 'react-native'

import { styles } from 'styles'
import { space } from '@/styles/constants'

const style = StyleSheet.create({
  outer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    minHeight: styles.height.one.height,
    paddingLeft: space[2],
    paddingTop: space[2],
    paddingBottom: space[2],
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  }
})

const Container = ({
  to,
  params,
  onPress,
  children,
})=> {
  if(to){
    return (
      <Link to={to} params={params} onPress={onPress} style={style.container}>
        {children}
      </Link>
    )
  }

  if(onPress){
    return (
      <TouchableOpacity
        onPress={onPress}
        style={style.container}
      >
        {children}
      </TouchableOpacity>
    )
  }

  return(
    <View style={style.container}>
      {children}
    </View>
  )
}

Container.propTypes = {
  onPress: PropTypes.func,
  children: PropTypes.node.isRequired,
}

const MenuItem = ({
  children,
  leftIcon,
  onPress,
  to,
  params,
  borderless,
  rightIcon,
  containerStyle,
}) => {

  return (
    <View style={{
      ...style.outer,
      ...(borderless ? {} : styles.border.bottom),
      ...containerStyle,
    }}>
      <Container onPress={onPress} to={to} params={params}>
        {leftIcon ? (
          <View style={{marginRight: space[1]}}>
            {leftIcon}
          </View>
        ) : null}
        {children}
      </Container>
      {rightIcon ? rightIcon : null}
    </View>
  )

}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  leftIcon: PropTypes.node,
  onPress: PropTypes.func,
  borderless: PropTypes.bool,
  rightIcon: PropTypes.node,
}

export default MenuItem
