import React from 'react'
import { PropTypes } from 'prop-types'

// Image from react-native-web had a bug that it will crush
// on mobile Safari when the image prop was updated.
const WebImage = props => {

  const {
    resizeMode = 'contain',
    source,
    onError,
  } = props

  const uri = source?.uri
  //const ref = useRef(null)

  //useEffect(()=> {
  //  if(ref?.current && onLoadEnd){
  //    ref.current.addEventListener('onloadend', onLoadEnd)
  //  }
  //  return ()=> {
  //    ref.current.removeEventListener('onloadend', onLoadEnd)
  //  }
  //}, [onLoadEnd, uri])

  if(!uri) return null

  return (
    <img
      //ref={ref}
      style={{
        width: '100%',
        height: '100%',
        objectFit: resizeMode,
      }}
      src={uri}
      onError={onError}
      loading='lazy'
    />
  )

}

WebImage.propTypes = {
  source: PropTypes.object,
  resizeMode: PropTypes.string,
  onError: PropTypes.func,
  onLoadEnd: PropTypes.func,
}

export default WebImage
