
import merge from 'deepmerge'
import { array } from 'utils/array'
import { clear as clearErrors } from 'features/errors'
import { bookings } from 'features/bookings'

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max))
}

export const onCreateGood = ()=> {
  return (dispatch) => {
    // set temporary id to manipulate
    const id = getRandomInt(9999999)
    dispatch({
      type: types.onCreateGood,
      id
    })

    return id
  }
}

export const clearEditingGood = ()=> {
  return (dispatch, getState) => {
    const state = getState()
    const authId = state.auth.id
    const groupIds = state.entities.users[authId]?.groups
    const groupsFolders = array.toMap(groupIds)
    dispatch({
      type: types.setEditingGood,
      editingGood: { groupsFolders }
    })
  }
}

export const updateNewBooking = (partial) => {
  return (dispatch, getState) => {

    // clear errors when something has changed
    const current = getState().drafts.newBooking || {}
    Object.keys(partial).forEach(key => {
      if(partial[key] !== current[key]){
        dispatch(clearErrors())
      }
    })

    dispatch({
      type: types.updateNewBooking,
      newBooking: partial
    })
  }
}

export const types = {
  setEditingGood: 'drafts/setEditingGood',
  mergeEditingGood: 'drafts/mergeEditingGood',
  clearEditingGood: 'drafts/clearEditingGood',
  onCreateGood: 'drafts/onCreateGood',
  removeCreatingGood: 'drafts/removeCreatingGood',
  updateEditingGood: 'drafts/updateEditingGood',
  updateEditingPost: 'drafts/updateEditingPost',
  clearEditingPost: 'drafts/clearEditingPost',
  updateNewBooking: 'drafts/updateNewBooking',
  set: 'drafts/set',
  clear: 'drafts/clear',
}

const initialState = {
  editingGood: {},
  creatingGoods: [], // array of new goods to show while posting
  //editingPost: {}, // it is added later so it could be undefined
  //newBooking: {},
}


export default (state = initialState, action) => {
  switch (action.type) {

    case types.onCreateGood: {
      return{
        ...state,
        creatingGoods: [
          ...(state.creatingGoods ? state.creatingGoods : []),
          {
            id: action.id,
            ...state.editingGood
          }
        ],
        // inherit some variables from previous post
        editingGood: {
          maxDays: state.editingGood.maxDays,
          marginDays: state.editingGood.marginDays,
          isHourly: state.editingGood.isHourly,
          userId: state.editingGood.userId,
          groupsFolders: state.editingGood.groupsFolders
        },
      }
    }

    case types.removeCreatingGood: {
      const filtered = state.creatingGoods?.filter(g => g?.id !== action?.id) || []
      return {
        ...state,
        creatingGoods: [
          ...filtered
        ]
      }
    }

    case types.setEditingGood: {
      // remove associated records like bookings etc, since they are not needed
      // and could cause self reference error
      const {
        bookings: _bookings,
        next_booking: _next_booking,
        schedules: _schedules,
        comments: _comments,
        ...editingGood
      } = action.editingGood

      return{
        ...state,
        editingGood
      }
    }

    case types.updateEditingGood: {
      return{
        ...state,
        editingGood: {
          ...state.editingGood,
          ...action.editingGood
        }
      }
    }

    case types.mergeEditingGood: {
      return {
        ...state,
        editingGood: merge(state.editingGood, action.editingGood)
      }
    }

    case types.clearEditingGood: {
      return {
        ...state,
        editingGood: {}
      }
    }

    case types.updateEditingPost: {
      return{
        ...state,
        editingPost: {
          ...state.editingPost,
          ...action.editingPost
        }
      }
    }

    case types.clearEditingPost: {
      return {
        ...state,
        editingPost: {}
      }
    }

    case types.updateNewBooking: {

      const { newBooking } = action
      let { duration } = newBooking

      const hours = bookings.hoursFromParams(newBooking)
      if(hours <= 0) duration = 0

      return {
        ...state,
        newBooking: {
          ...state.newBooking,
          ...action.newBooking,
          duration,
        }
      }
    }

    case types.set: {
      return {
        ...state,
        ...action.state,
      }
    }

    case types.clear: {
      return initialState
    }

    default: {
      return state
    }
  }
}
