import React from 'react'
import { PropTypes } from 'prop-types'

import View from 'components/View'
import Text from 'components/Text'

import { styles } from 'styles'
import { space } from 'styles/constants'
import useColorScheme from 'hooks/useColorScheme'

const SectionHeader = props => {

  const theme = useColorScheme()

  const {
    label,
    style,
    headerRight,
    containerStyle,
  } = props

  return (
    <View style={{
      backgroundColor: styles.color[theme].onBackground,
      ...containerStyle,
    }}>
      <View row spaceBetween style={{
        backgroundColor: styles.color[theme].overlay.nine,
        padding: space[0.5],
        ...style
      }}>
        <Text size='sm'>{label}</Text>
        {headerRight}
      </View>
    </View>
  )

}

SectionHeader.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  headerRight: PropTypes.node,
}

export default SectionHeader
