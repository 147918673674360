import { Link as NativeLink } from '@react-navigation/native'
import { View } from 'react-native'

// takes same props as NativeLink
type Props = React.ComponentProps<typeof NativeLink>
const Link = (props: Props) => {
  const { style, ...rest } = props

  return (
    <View style={style}>
      <NativeLink {...rest} />
    </View>
  )
}

export default Link
