import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native'
import { space, color, elevation } from '@/styles/constants'

const styles = StyleSheet.create({
  container: {
    marginTop: space[1],
    marginLeft: space[1],
    marginRight: space[1],
    borderRadius: space[1],
    ...elevation[3],
  },
  inner: {
    backgroundColor: color.overlay[3],
    padding: space[1],
    borderRadius: space[1],
  }
})

type Props = {
  color: string
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}

const ItemContainer = ({
  color,
  children,
  style,
}: Props) => {

  return(
    <View style={[
      styles.container,
      { backgroundColor: color },
      style,
    ]}>

      <View style={styles.inner}>
        {children}
      </View>
    </View>
  )
}

export default ItemContainer
