import * as Linking from 'expo-linking'
import Configs from 'configs/Configs'
import Notifications from 'lib/Notifications'

// remove any non necessary characters
export const cleanId = (maybeString) => {
  const string = decodeURIComponent(`${maybeString}`)
  return string.replace(/"|'/g, '')
}

// https://reactnavigation.org/docs/deep-linking/#uri-prefix
const prefix = Linking.createURL('/')
const webPrefix = Configs.webBaseUrl

export const HomeScreens = {
  All: 'All',
  Liked: 'liked',
  UsageHistory: 'booked',
  Group0: 'groups/0',
  Group1: 'groups/1',
  Group2: 'groups/2',
  Group3: 'groups/3',
  Group4: 'groups/4',
  Group5: 'groups/5',
  Group6: 'groups/6',
  Group7: 'groups/7',
  Group8: 'groups/8',
  Group9: 'groups/9',
  Group10: 'groups/10',
}

const config = {
  prefixes: [ prefix, webPrefix, `https://*.${Configs.webDomain}` ],
  async getInitialURL() {
    // Check if app was opened from a deep link
    let url = await Linking.getInitialURL()

    if (url != null) {
      return url
    }

    const response = await Notifications.getLastNotificationResponseAsync()
    url = response?.notification.request.content.data.url
    return url
  },
  config: {
    screens: {
      // stacks
      Main: {
        path: '',
        screens: {
          Auth: '',
          AuthRedirect: 'auth_redirect',
          AuthToken: {
            path: 'auth_tokens/:jti',
            parse: { jti: String }
          },
          Landing: {
            path: 'lp/:lng?',
          },
          LandingBooks: 'lp/books',
          Languages: {
            path: 'lng/:id',
            parse: { id: String }
          },
          SignUp: 'sign_up',

          SignIn: 'sign_in',
          SignInMethods: 'sign_in_methods',
          EmailSignIn: 'email_sign_in',
          MembershipSignIn: 'member_sign_in',
          UserSignUp: 'users/new',
          Home: {
            path: 'home',
            screens: HomeScreens,
          },

          RedirectHome: 'redirect_home',
          NewGood: 'goods/new',
          GoodsImportSelect: 'goods/new/import_select',
          CSVImport: 'goods/new/csv_import',
          ISBNImport: 'goods/new/isbn_import',
          CSVImportEdit: 'goods/edit/csv_import',
          Tutorial: 'tutorial',

          Good: {
            path: 'goods/:id',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditGood: {
            path: 'goods/:id/edit',
            parse: { id: String }
          },
          GoodBookings: {
            path: 'goods/:id/bookings',
            parse: { id: String }
          },
          GoodBookingsHistory: {
            path: 'goods/:id/bookings/history',
            parse: { id: String }
          },
          NewBooking: {
            path: 'goods/:goodId/bookings/new',
            parse: { goodId: String }
          },
          NewBookingNow: {
            path: 'goods/:goodId/bookings/new/now',
            parse: { goodId: String }
          },
          BulkBooking: {
            path: 'bookings/new/bulk',
          },
          GoodTransfer: {
            path: 'goods/:id/edit/transfer',
            parse: { id: String }
          },
          GoodReviews: {
            path: 'goods/:id/reviews',
            parse: { id: String }
          },
          GoodComments: {
            path: 'goods/:id/comments',
            parse: { id: String }
          },

          User: {
            path: 'users/:id',
            parse: { id: (id)=>  cleanId(id) }
          },
          UserEdit: {
            path: 'users/:id/edit',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditUserManagers: {
            path: 'users/:id/managers/edit',
            parse: { id: (id)=>  cleanId(id) }
          },
          UserNotificationSetting: {
            path: 'notification_setting',
          },
          Webhooks: {
            path: 'webhooks',
          },
          UserGroups: {
            path: 'groups',
          },
          UserDelete: {
            path: 'users/:id/delete',
            parse: { id: (id)=>  cleanId(id) }
          },
          UserGoodsPicker: {
            path: 'users/:id/goods_picker',
            parse: { id: (id)=>  cleanId(id) }
          },
          UserReminderSetting: {
            path: 'user_reminder_setting',
          },

          Bookings: {
            path: 'bookings',
            screens: {
              BookingsToUse: 'use',
              BookingsToLend: 'lend',
              AllBookings: 'all'
            },
          },

          BookingsHistory: 'bookings_history',
          BookingsSearch: 'bookings/search',
          Booking: {
            path: 'bookings/:id',
            parse: { id: (id)=>  cleanId(id) }
          },
          BookingComplete: {
            path: 'bookings/:id/complete',
            parse: { id: (id)=>  cleanId(id) }
          },

          PaymentMethod: 'payments/method',
          PaymentHistory: 'payments/history',

          Payment: {
            path: 'payments/:id',
            parse: { id: (id)=>  cleanId(id) }
          },

          NewGroup: {
            path: 'groups/new',
            parse: { id: String }
          },
          Group: {
            path: '-/:id',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditGroup: {
            path: '-/:id/edit',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupTransfer: {
            path: '-/:id/edit/transfer',
            parse: { id: String }
          },
          NewGroupMember: {
            path: '-/:id/members/new',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditGroupMembers: {
            path: '-/:id/members/edit',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditGroupFolders: {
            path: '-/:id/edit/folders',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupNotificationSetting: {
            path: '-/:id/notification_setting',
            parse: { id: (id)=>  cleanId(id) }
          },
          ExportGoodsQRCode: {
            path: '-/:id/export/goods_qr_code',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupAuthTokens: {
            path: '-/:id/auth_tokens',
            parse: { id: (id)=>  cleanId(id) }
          },
          ExportGroupAuthTokens: {
            path: '-/:id/auth_tokens/export',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupAuthTokensSpreadsheetImport: {
            path: '-/:id/auth_tokens/import',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupSignUp: {
            path: '-/:id/sign_up',
            parse: { id: (id)=>  cleanId(id) }
          },
          EditGroupSignUp: {
            path: '-/:id/sign_up/edit',
            parse: { id: (id)=>  cleanId(id) }
          },
          NewGroupPost: {
            path: '-/:id/posts/new',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupPosts: {
            path: '-/:id/posts',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupPostEdit: {
            path: '-/:groupId/posts/:postId',
            parse: { groupId: String, postId: String }
          },
          GroupGoodsPicker: {
            path: '-/:id/goods_picker',
            parse: { id: (id)=>  cleanId(id) }
          },
          GroupEmailInvite: {
            path: '-/:id/invite/email',
            parse: { id: (id)=>  cleanId(id) }
          },

          Search: {
            path: 'search',
          },

          SearchSuggest: {
            path: 'search/suggest',
          },

          LanguageSetting: {
            path: 'languages/:id',
            parse: { id: String },
          },
          TimeZoneSetting: {
            path: 'time_zone_setting',
          },
          SharedDeviceSetting: {
            path: 'device_setting',
          },

          // swtich between v1 and v2 subscription
          Upgrade: 'subscriptions/upgrade',
          LegacyUpgrade: 'subscriptions/legacy_upgrade', // v1 subscription
          Pricing: 'subscriptions/pricing', // v2 subscription

          BarcodeScanner: 'barcode-scanner',
          QRCodeScanner: 'qrcode-scanner',
          Support: 'support',
          Privacy: 'privacy',
          Terms: 'terms',
          CommerceLaw: 'commerce_law',

          // modals
          HelpModal: 'help',
          DisplayOptions: 'display_options',
          SortOptions: 'sort_options',
          Preview: {
            path: 'preview/:id',
            parse: { id: String }
          },
          ShareGood: {
            path: 'goods/:id/share',
            parse: { id: String }
          },
          FolderPicker: {
            path: '-/:groupId/folder_picker',
            parse: { groupId: String }
          },

          NotFound: '*',
        },
      }
    },
  },
}

import { objToQueryString } from 'utils'

export const parseToPath = (to, params) => {
  if(to === 'HelpModal'){
    return `/help?${objToQueryString(params)}`
  }

  if(to === 'Search'){
    // navigation with objToQueryString will cause iOS to crash
    return `/search?query=${params.query}`
  }

  let screen = config.config.screens['Main'].screens[to]
  if(!screen){
    screen = config.config.screens[to]
  }
  if(!screen?.path){ // no parse options
    return '/' + screen
  }

  // replace with params id
  let path = screen.path
  path = path.replace(/:id|:goodId|:userId|:groupId|:postId/g, function (m) {
    return {
      ':id': params?.id,
      ':goodId': params?.goodId,
      ':userId': params?.userId,
      ':groupId': params?.groupId,
      ':postId': params?.postId,
    }[m]
  })

  return `/` + path
}

export default config
