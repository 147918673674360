
export const set = (networks)=> {
  return (dispatch, getState) => {
    // don't update when the state is the same
    if(getState().networks?.isConnected === networks?.isConnected){
      return
    }

    dispatch({
      type: types.set,
      networks,
    })
  }
}

const types = {
  set: 'networks/set',
  clear: 'networks/clear',
}

const initialState = {
  isConnected: true,
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.set: {
      return{
        ...state,
        ...action.networks
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
