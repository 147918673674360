import { StyleSheet } from 'react-native'
import ScrollView from '@/components/ScrollView'
import { space } from '@/styles/constants'

const styles = StyleSheet.create({
  box: {
    margin: space[2],
    backgroundColor: 'white',
    shadowColor: 'rgba(0,0,0,0.25)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    elevation: 3,
    flexGrow: 0,
    borderRadius: space[1]
  }
})

type Props = {
  children: React.ReactNode,
  style?: object,
  paddingless?: boolean,
}

const DialogBox = ({
  paddingless,
  children,
  style,
}: Props) => {

  return (
    <ScrollView style={[
      styles.box,
      paddingless ? {} : { padding: space[2], },
      style,
    ]}>
      {children}
    </ScrollView>
  )

}

export default DialogBox
