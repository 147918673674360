import Application from 'lib/Application'
import Linking from 'lib/Linking'
import Platform from 'lib/Platform'
import IntentLauncher from 'lib/IntentLauncher'

export const openNativeSetting = () => {
  if(Platform.OS === 'ios'){
    const uri = `app-settings://${Application.applicationId}`
    Linking.openURL(uri)
  }else{
    IntentLauncher.startActivityAsync(
      IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS,
      {
        data: `package:${Application.applicationId}`,
      }
    )
  }
}

const set = (permission)=> {
  return dispatch => {
    dispatch({
      type: types.set,
      permission
    })
  }
}

export const permissions = {
  set
}

const types = {
  set: 'permissions/set',
  clear: 'permissions/clear',
}

const initialState = {
  notifications: {
    asked: null,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.set:
      return{
        ...state,
        ...action.permission,
      }
    case types.clear:
      return initialState
    default:
      return state
  }
}
