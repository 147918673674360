import { useEffect, useState } from 'react'

import AppState from 'lib/AppState'
import Updates from 'lib/Updates'
import Alert from 'lib/Alert'
import Configs from 'configs/Configs'
import { capture } from 'sentry'
import { t } from '@/i18n'

// useUpdate does not work as expected anymore, since expo-updates are changed
const useUpdate = ()=> {
  const [isAvailable, setIsAvailable] = useState(false)

  // true if it is more than minor version update (not patch)
  const [isMinor, setIsMinor] = useState(false)

  const askToReload = ()=> {
    Alert.alert(
      t('alerts.update.title'),
      t('alerts.update.description'),
      [
        { text: t('alerts.update.ok'), onPress: async()=> {
          await Updates.reloadAsync()
        } },
        { text: t('alerts.update.cancel'), onPress: ()=>{}, style: 'cancel'}
      ]
    )
  }

  const check = async() => {
    try{
      if(Configs.env === 'development') return

      const checked = await Updates.checkForUpdateAsync()
      if(checked.isAvailable){
        await Updates.fetchUpdateAsync()
        setIsAvailable(true)
      }else{
        setIsAvailable(false)
        setIsMinor(false)
      }
      // always show if it is a staging
      if(Configs.env === 'staging' && checked?.isAvailable){
        setIsMinor(true)
      }

    }catch(err){
      console.error(err)
      capture(err)
    }
  }

  const handleStateChange = (next)=> {
    if (next === 'active') {
      check()
    }
  }

  useEffect(()=> {
    // check on app start
    check()
    // check on app return from background
    const sub = AppState.addEventListener('change', handleStateChange)
    return () => {
      sub?.remove?.()
    }
  }, [])

  return {
    isAvailable,
    isMinor: isAvailable && isMinor,
    askToReload,
  }

}

export default useUpdate
