import RootNavigation from 'navigation/RootNavigation'

const types = {
  set: 'routes/set',
  clear: 'routes/clear',
}

const initialState = {
  visitedGroupId: null,
  goBackTo: {
    // name,
    // params,
  }
}

export const setGoBackToCurrent = ()=> {
  return (dispatch) => {
    ppError('DEPRECATED: RootNavigation.getCurrentRoute is unstable so do not use this.')
    const currentRoute = RootNavigation.getCurrentRoute()
    setGoBackTo(currentRoute)(dispatch)
  }
}

export const setGoBackTo = (route)=> {
  return (dispatch)=> {
    dispatch({
      type: types.set,
      routes: {
        goBackTo: {
          ...route,
          key: undefined
        }
      }
    })
  }
}

export const clearGoBackTo = ()=> {
  return (dispatch)=> {
    dispatch({
      type: types.set,
      routes: {
        goBackTo: {}
      }
    })
  }
}

export const routes = {
  setGoBackToCurrent,
  setGoBackTo,
  clearGoBackTo,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.set:
      return{
        ...state,
        ...action.routes
      }
    case types.clear:
      return initialState
    default:
      return state
  }
}
