import { configureStore } from '@reduxjs/toolkit'

import {
  useStore as useReduxStore,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux'
import {
  createSelector as createReSelector,
} from '@reduxjs/toolkit'

import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@/lib/AsyncStorage'
import rootReducer from '@/reducers'

import type { Action, Reducer } from "redux"
import type { PersistConfig, PersistState } from "redux-persist"
declare module "redux-persist" {
  export function persistReducer<S, A extends Action = Action, P = S>(
    config: PersistConfig<S>,
    baseReducer: Reducer<S, A, P>,
  ): Reducer<
    S & { _persist: PersistState },
    A,
    P & { _persist?: PersistState }
  >
}

const persistConfig = {
  key: 'root',
  version: 1, // データの構造が変わった時などに利用する
  storage: AsyncStorage,
  blacklist: [
    // auth is saved and loaded via to SecureStorage
    'auth',
    'entities',
    'flash',
    'sync',
    'errors',
    'goods',
    'networks',
    'ads',
  ],
  // lower throttle on Android will cause serious performance issues
  throttle: 1000,
}

// rootReducerを永続化する
const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
)

// 永続化されたReducerと他のミドルウェアからStoreを作成する
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    if(process.env.NODE_ENV === 'test') {
      // check serializable and immutable in test
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      })
    }else if(process.env.NODE_ENV === 'development') {
      const { logger } = require(`redux-logger`)
      // dont's check serializable and immutable in development (for performance)
      // but add logger
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(logger)
    }else{
      // Don't add any dev related middleware in default (production) mode
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
    }
  },
})

// <PersistGate> コンポーネントで利用
export const persistor = persistStore(store)

// Infer the `RootState` and `Dispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type Dispatch = typeof store.dispatch
export type GetState = ()=> RootState

// directly using { useStore } from 'react-redux' will not have correct type definitions
export const useStore = ()=> {
  const { dispatch, getState } = useReduxStore()
  return {
    dispatch: dispatch as Dispatch,
    getState: getState as GetState,
  }
}

export const useDispatch = ()=> useReduxDispatch<Dispatch>()

export const useSelector = (
  selector: (state: RootState)=> any,
  equalityFn?: (left: any, right: any)=> boolean
)=> useReduxSelector(selector, equalityFn)

export const createSelector = createReSelector.withTypes<RootState>()

// 起動時にstoreから読み取れないなど何かしらの問題が発生した時はpurgeする
// persistor.purge()
