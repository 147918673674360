import { array } from '@/utils/array'
import { t } from '@/i18n'
import { flash } from '@/features/flash'
import Alert from '@/lib/Alert'
import { Dispatch, GetState } from '@/store'
import type { Good } from '@/types'

type ValidateParams = {
  good: Good
  group_ids: string[]
}

type AsyncAlertParams = {
  title: string
  description: string
}

export type CellValue = string | undefined | boolean | React.ReactNode
// for Table
export type RowData = {
  [key: string]: CellValue
  id?: CellValue
  title?: CellValue,
  isbn?: CellValue,
  description?: CellValue,
  image_url?: CellValue,
  tags?: CellValue,
  meta?: CellValue,
  hasError?: boolean
  hasWarning?: boolean
}

export const importableAttributes = {
  title: '',
  isbn: '',
  description: '',
  image_url: '',
  max_days: '',
  quantity: '',
  margin_days: '',
  is_hidden: '',
  is_hourly: '',
  restrict_reservation:'',
  for_reference_only: '',
  is_exclusive:'',
  is_public_use: '',
  category: '',
  location: '',
  publisher: '',
  authors_list: '',
  management_id: '',
  purchase_price: '',
  release_date: '',
  auto_extend: '',
}

const AsyncAlert = ({title, description}: AsyncAlertParams): Promise<boolean> => {
  return new Promise((resolve) => {
    Alert.alert(
      title,
      description,
      [
        { text: t('general.cancel', { style: 'cancel' }), onPress: () => resolve(false) },
        { text: t('general.ok'), onPress: () => resolve(true) },
      ],
      { cancelable: false }
    )
  })
}

export const validate = async(params: ValidateParams): Promise<boolean> => {
  const { good } = params
  let validated = true
  if(good.is_public_use === false && params.group_ids.length === 0){
    validated = false
    validated = await AsyncAlert({
      title: t('general.confirm'),
      description: t('warnings.good.noGroups'),
    })
  }
  return validated
}

export const togglePickedId = (pickedId: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const good = getState().entities?.goods[pickedId]
    if(good?.is_hourly) {
      flash.add({ type: 'error', body: t('errors.booking.bulkBookHourly') })(dispatch)
      return
    }
    if(good?.for_reference_only) {
      flash.add({ type: 'error', body: t('errors.booking.bulkBookReferenceOnly') })(dispatch)
      return
    }
    if(good?.restrict_reservation) {
      flash.add({ type: 'error', body: t('errors.booking.bulkBookRestrictReservation') })(dispatch)
      return
    }
    if(good?.price_per_day || good?.price_per_week || good?.price_per_month) {
      flash.add({ type: 'error', body: t('errors.booking.bulkBookPrice') })(dispatch)
      return
    }
    dispatch({
      type: types.togglePickedId,
      pickedId
    })
  }
}

export const clearPickedIds = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: types.clearPickedIds })
  }
}
const types = {
  togglePickedId: 'goods/togglePickedId',
  clearPickedIds: 'goods/clearPickedIds',
} as const

type TogglePickedId = {
  type: typeof types.togglePickedId
  pickedId: string
}
type ClearPickedIds = {
  type: typeof types.clearPickedIds
}

type Action = TogglePickedId | ClearPickedIds

const initialState = {
  pickedIds: [] as string[],
}

type State = typeof initialState

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case types.togglePickedId: {
      const { pickedId } = action
      const found = state.pickedIds.some(id => id === pickedId)
      const nextIds = found
        ? state.pickedIds.filter(id => id !== pickedId)
        : [...state.pickedIds, pickedId]
      return {
        ...state,
        pickedIds: array.uniq(nextIds)
      }
    }
    case types.clearPickedIds: {
      return {
        ...state,
        pickedIds: [],
      }
    }
    default: {
      return state
    }
  }
}
