import StoreReview from '@/lib/StoreReview'
import { Dispatch, GetState } from '@/store'

const types = {
  set: 'home/set',
  addGroupIds: 'home/addGroupIds',
  clear: 'home/clear',
} as const

type State = {
  availableFilter: boolean
  displayOption: 'icon' | 'list' | 'time'
  sortOption: 'latest' | 'popular' | 'alphabetic' | 'bookedAt'
  filterOption: 'none' | 'available' | 'inUse'
  reviewRequested: boolean
  defaultTabName: string | null
  dismissPlanSuggest: string | null
  groupIds: string[]
  hideNps: boolean
}

type SetAction = {
  type: typeof types.set,
  home: Partial<State>
}
type AddGroupIdsAction = {
  type: typeof types.addGroupIds,
  groupIds: string[]
}
type ClearAction = {
  type: typeof types.clear
}
type Action = SetAction | AddGroupIdsAction | ClearAction

const initialState: State = {
  availableFilter: false,
  displayOption: 'icon',
  sortOption: 'latest',
  filterOption: 'none',
  reviewRequested: false,
  defaultTabName: null,
  dismissPlanSuggest: null,
  groupIds: [],
  hideNps: false,
}

import { HomeScreens } from '@/navigation/LinkingConfiguration'

type HomeScreen = keyof typeof HomeScreens
export const setDefaultTab = (defaultTabName: HomeScreen) => {
  // it used to use Group0, All etc, but using path now
  let path = ''
  if(defaultTabName === 'UsageHistory') path = 'booked'
  if(defaultTabName === 'Liked') path = 'liked'
  if(defaultTabName === 'All') path = 'All'

  const isGroupTab = /^Group[0-9]+/.test(defaultTabName)
  if(isGroupTab){
    const groupNumber = defaultTabName.replace('Group', '')
    path = `groups/${groupNumber}`
  }

  return {
    type: types.set,
    home: { defaultTabName: path }
  }
}

export const requestReview = ()=> {
  return async(dispatch: Dispatch, getState: GetState) => {

    // do nothing when it is not available
    if(!await StoreReview.isAvailableAsync()) return

    // do not request if already requested.
    if(getState().home.reviewRequested) return

    // do not request if user has low nps score
    const nps = getState().entities?.users[getState().auth.id]?.nps || 0
    if(nps <= 7) return

    await StoreReview.requestReview()
    dispatch({ type: 'home/set', home: { reviewRequested: true } })
  }
}

export const displayOptions = {
  options: ['icon', 'text', 'list', 'time'],
  iconMap: {
    undefined: 'th-large',
    icon: 'th-large',
    text: 'list',
    list: 'calendar-alt',
    time: 'clock'
  },
  regularMap: {
    list: true,
    time: true
  },
}

export const sortOptions = {
  options: ['latest', 'popular', 'alphabetic', 'bookedAt'],
  iconMap: {
    undefined: 'clock',
    latest: 'clock',
    popular: 'fire-alt',
    alphabetic: 'sort-alpha-down',
    bookedAt: 'history'
  },
  regularMap: {
    undefined: true,
    latest: true
  },
}

export const filterOptions = {
  options: ['none', 'available', 'inUse'],
  iconMap: {
    none: 'filter',
    null: 'filter',
    undefined: 'filter',
    available: 'calendar',
    inUse: 'calendar',
  },
  regularMap: {
    available: true,
    inUse: true
  },
}

export const home = {
  displayOptions, sortOptions, filterOptions
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case types.set: {
      return{
        ...state,
        ...action.home
      }
    }
    case types.addGroupIds: {
      const current = state.groupIds || []
      return {
        ...state,
        groupIds: [...current, ...action.groupIds]
      }
    }
    case types.clear:
      return initialState
    default:
      return state
  }
}
