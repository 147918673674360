import * as React from 'react'
import TouchableOpacity from 'components/TouchableOpacity'

import Platform from 'lib/Platform'
import Linking from 'lib/Linking'
import {
  Link as NavigationLink,
  useRoute,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native'
import View from 'components/View'
import { parseToPath } from 'navigation/LinkingConfiguration'

const Link = props => {

  const {
    to,
    params,
    style,
    children,
    onPress,
    external,
    // action does not work
    // action,
    disabled,
  } = props

  const route = useRoute()

  // using NativeLink allow user to right click links in web
  // maybe we should disable `onPress` pattern since it does not relate to navigation
  if(to && !external){
    const webStyle = Platform.OS === 'web' ? {
      width: '100%', height: '100%'
    } : {}

    // getFocusedRouteNameFromRoute only works on native
    const focusedRouteName = getFocusedRouteNameFromRoute(route)
    const lastRoute = focusedRouteName ? { name: focusedRouteName } : route
    // do not link if at same route
    const isEqual = lastRoute?.name === to &&
      lastRoute?.params?.id === params?.id &&
      lastRoute?.params?.goodId === params?.goodId &&
      lastRoute?.params?.userId === params?.userId ||
      (lastRoute?.name === 'Landing' && to === 'Home') ||
      (lastRoute?.name === 'All' && to === 'Home') ||
      (lastRoute?.name === 'Main' && to === 'Home')
      // cound be undefined === undefined
    if(isEqual || disabled){
      return (
        <View id={`view-link-${to}`}
          style={{ ...webStyle, ...style }}
        >
          {children}
        </View>
      )
    }

    // NavigationLink has difficult styling issue
    // so it might be better to use TouchableOpacity
    // on native environment
    return (
      <NavigationLink
        id={`link-${to}`}
        to={parseToPath(to, params)}
        onPress={onPress}
        // StackActions.push will cause multiple routes to be mounted.
        // so do not use thoese.
        //action={StackActions.push(to, params)}
        style={{
          // to check if the visible area match the touchable
          //overflow: 'hidden',
          ...webStyle,
          ...style
        }}
      >
        <View style={{
          //overflow: 'hidden',
          ...webStyle,
          ...style
        }}>
          {children}
        </View>
      </NavigationLink>
    )
  }

  if(external && Platform.OS === 'web'){
    return (
      <a href={to} style={{
        display: 'flex',
        textDecoration: 'none',
        width: '100%', height: '100%',
        ...style,
      }} target='_blank' rel='noreferrer'
      onClick={onPress}
      >
        {children}
      </a>
    )
  }

  if(external){
    return (
      <TouchableOpacity style={{
        ...style,
      }}
      id={`link-${to}`}
      onPress={()=> Linking.openURL(to)}
      >
        {children}
      </TouchableOpacity>
    )
  }

  const handlePress = () => {
    if(onPress){
      onPress()
    }
    p('handlePress')
    if(external){
      Linking.openURL(to)
      return
    }
  }

  return(
    <TouchableOpacity
      id={`link-${to}`}
      activeOpacity={0.8}
      style={{
        ...(
          Platform.OS === 'web' ? { width: '100%', height: '100%' } : {}
        ),
        ...style
      }}
      onPress={handlePress}
    >
      {children}
    </TouchableOpacity>
  )
}

import { PropTypes } from 'prop-types'

Link.propTypes = {
  to: PropTypes.string,
  params: PropTypes.object,
  style: PropTypes.object,
  children: PropTypes.node,
  onPress: PropTypes.func,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
}

Link.parseToPath = parseToPath

export default Link

