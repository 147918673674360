import Configs from 'configs/Configs'
const anon = Configs.supabase.anonKey

const handleFetch = (url, body, method)=> {
  return (_dispatch, _getState)=> {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${anon}`,
      },
      body: JSON.stringify(body),
    })
  }
}
const put = (url, body)=> {
  return (dispatch, getState)=> {
    return handleFetch(url, body, 'PUT')(dispatch, getState)
  }
}

const post = (url, body)=> {
  return (dispatch, getState)=> {
    return handleFetch(url, body, 'POST')(dispatch, getState)
  }
}

export const supabase = {
  put,
  post
}

export default supabase
