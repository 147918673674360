import TouchableOpacity from 'components/TouchableOpacity'
import useDynamicGrid from 'hooks/useDynamicGrid'
import { View, StyleSheet } from 'react-native'
import { space } from 'styles/constants'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  touchable: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: space[4],
  }
})

const Background = ({
  children,
  onPress,
  style,
})=> {

  const { width } = useDynamicGrid({ size: 12 })

  return(
    <View style={styles.container}>
      <TouchableOpacity id='touchable-background' style={[
        styles.touchable,
        { width, },
        style,
      ]}
      onPress={onPress}
      activeOpacity={0.9}
      >
        {children}
      </TouchableOpacity>
    </View>
  )
}

export default Background
