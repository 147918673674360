import { useEffect } from 'react'
import Text from '@/components/Text'
import View from '@/components/View'

import NetInfo from '@/lib/NetInfo'
import { StyleSheet } from 'react-native'
import AppState from '@/lib/AppState'
import { useSelector, useStore } from '@/store'
import { set } from '@/features/networks'
import { t } from '@/i18n'
import { space, height } from '@/styles/constants'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: space[0.5],
    marginTop: height.statusBar,
    marginBottom: space[0.5],
  },
})

const NetworkStatus = () => {
  const netInfo = NetInfo.useNetInfo()
  const { dispatch, getState } = useStore()

  const isConnected = useSelector((state) => {
    const current = state.networks?.isConnected
    return current === false ? false : true
  })

  useEffect(()=> {
    set({ isConnected: netInfo?.isConnected })(dispatch, getState)
  }, [netInfo?.isConnected])

  useEffect(()=> {
    const sub = AppState.addEventListener('change', onAppStateChange)
    return ()=> {
      sub?.remove?.()
    }
  }, [])

  const onAppStateChange = async(e: string) => {
    if(e !== 'active') return null
    const netState = await NetInfo.fetch()
    set({ isConnected: netState.isConnected })(dispatch, getState)
  }

  if(!isConnected){
    return (
      <View style={styles.container}>
        <Text size='xs' center inverted>
          {t('labels.network.noConnection')}
        </Text>
      </View>
    )
  }else{
    return null
  }
}

export default NetworkStatus
