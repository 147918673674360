
import '@ungap/global-this'
import fromEntries from 'object.fromentries'
import matchAll from 'string.prototype.matchall'

if (!Object.fromEntries) {
  fromEntries.shim()
}

matchAll.shim()

// https://stackoverflow.com/questions/45849831/object-entries-alternative-for-internet-explorer-and-reactjs
if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

