import Sentry from 'lib/Sentry'
import { storage } from 'features/auth/storage'
import ExpoConstants from 'configs/ExpoConstants'
import jwt from 'utils/jwt'
import { capture as captureWithWebhook } from 'utils/error'

export const capture = async(error, extra) => {
  try {
    captureWithWebhook(error, extra)
  } catch (e) {
    console.error(e)
  }

  const auth = await storage.getAuth()

  Sentry.withScope((scope) => {
    scope.setTag("captureHandled", "true")
    scope.setLevel('warning')

    if (extra) {
      for (const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }

    // do not send anything sensitive
    let exp, tokenId
    if(auth.token){
      const parsed = jwt.parse(auth.token)
      exp = parsed.exp
      tokenId = parsed.id
    }

    scope.setUser({
      id: auth.id,
      token: { exp, id: tokenId },
      version: ExpoConstants?.expoConfig?.versionId
    })

    Sentry.captureException(error)
  })
}

export default Sentry
