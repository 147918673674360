import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import View from 'components/View'
import UserImage from './Image'
import UserName from './Name'

import { styles } from 'styles'
import { useSelector, useStore } from 'react-redux'
import { sync } from 'features/sync'
import { capture } from 'sentry'

const UserIconWithName = props => {

  const { dispatch, getState } = useStore()

  const {
    userId,
    size = 'md',
    linked,
    BelowName,
  } = props

  const hasEnoughData = useSelector(state => {
    const user = state.entities.users[userId]
    return user?.name
  })

  useEffect(()=> {
    if(userId && !hasEnoughData) fetchUser()
  }, [userId])

  const fetchUser = async()=> {
    if(typeof userId !== 'string'){
      capture(new Error('UserIconWithName: userId must be a string'), { userId })
      return
    }

    await sync.graphql({
      query: `{
        user(slug: "${userId}"){
          id, name,
          thumbnail_url: thumbnailUrl
        }
      }`,
      params: { key: 'user_icon_with_name' }
    })(dispatch, getState)
  }

  if(!hasEnoughData) return null

  return (
    <View row vcenter shrink>
      <UserImage
        linked={linked}
        size={size}
        userId={userId}
      />
      <View shrink style={{
        marginLeft: styles.space.sm
      }}>
        <UserName
          size={size}
          userId={userId}
          style={{ marginLeft: styles.space.sm }}
        />
        {BelowName ? BelowName : null}
      </View>
    </View>
  )

}

UserIconWithName.propTypes = {
  userId: PropTypes.string, // will return null if undefined
  size: PropTypes.string,
  linked: PropTypes.bool,
  BelowName: PropTypes.node,
}

export default UserIconWithName
