import capture from '@/sentry/simpleCapture'

// it returns a string of base64 with correct padding (correct length)
const takeBase64 = (token?: string): string | null => {
  if(!token) return null

  var base64Url = token.split('.')[1]
  if(!base64Url) return null

  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  while (base64.length % 4 !== 0) {
    base64 += '='
  }
  return base64
}

type MaybeParsed = {
  exp: number
  iss: string
  slug?: string
  id?: string
  jti?: string
  authorizable_type?: string
  authorizable_id?: string
} | false
const parse = (token?: string): MaybeParsed => {
  if(!token) return false

  try{
    const base64 = takeBase64(token)
    if(!base64) return false

    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  }catch(err){
    ppError(err)
    capture(err)
    return false
  }
}

const isLimitedToken = (token?: string) => {
  const parsed = jwt.parse(token)
  if(!parsed) return false

  return !!parsed.jti
}

const isValid = (token?: string) => {
  const parsed = jwt.parse(token)
  if(!parsed) return null
  // exp is seconds, but Date.now is ms
  const now = Date.now() / 1000
  return now <= parsed.exp
}

const isExpired = (token?: string) => {
  const parsed = jwt.parse(token)
  if(!parsed) return true

  const exp = parsed.exp
  if(!exp) return true
  return (Date.now() / 1000) > exp
}

const jwt = {
  takeBase64,
  parse,
  isLimitedToken,
  isValid,
  isExpired,
}

export default jwt
