import React from 'react'
import View from 'components/View'
import { styles } from 'styles'
import Icon from 'components/Icon'
import TouchableOpacity from 'components/TouchableOpacity'
import RootIcon from '@/navigation/buttons/RootIconButton'

import {
  useNavigationState,
} from '@react-navigation/native'
import useNavigation from '@/hooks/useNavigation'
import { useSelector } from 'react-redux'
import jwt from 'utils/jwt'

// common
//   has multiple routes -> pop
// logged_in
//   has any groups -> root to Home
//   has no groups ->  root to Group (with visitedGroupId)
// logged_out
//   has root route to Landing -> root to Landing
//   has root route to Group -> root to Group

// Visit Home will navigate depends on the user state
// logged_out -> navigate to Landing
// logged_in with jti token -> navigate to first Group

const HeaderBackButton = ({
  onPress,
})=> {
  const navigation = useNavigation()
  // TODO: make a function to return the main stack routes

  const handlePress = ()=> {
    if(onPress){
      onPress()
    }else{
      navigation.goBack()
    }
  }

  return (
    <View row>
      <RootIcon />
      <TouchableOpacity id='button-back'
        onPress={handlePress}
        style={{
          padding: styles.space.md,
          paddingRight: styles.space.lg,
          margin: 0,
        }}
      >
        <Icon name='chevron-left' />
      </TouchableOpacity>
    </View>
  )
}

const BackButton = () => {

  // make it always back'able to Group / Landing / Good for non-logged-in user

  const navigation = useNavigation()
  const routes = useNavigationState(state => state.routes)

  const token = useSelector(state => state.auth.token)

  const loggedIn = !!token
  // rootRoute name: 'Landing' will cause navigation bug on web
  let rootRoute = loggedIn ? { name: 'Home' } : { name: 'Main' }

  // MainStack is nested inside [{ name: 'Main', routes: [...] }] on native but not nested on web
  const previousRouteName = routes ? routes[routes.length -2]?.name : null
  // Do not allow user to go back to Tutorial, it will be buggy
  if(previousRouteName === 'Tutorial'){
    return <HeaderBackButton onPress={()=> navigation.reset({ index: 0, routes: [rootRoute] })} />
  }

  const canGoBack = navigation.canGoBack()
  // always use pop not goBack since goBack in web is buggy
  if(canGoBack){
    return (
      <HeaderBackButton />
    )
  }

  // below has always only 1 route (can't go back)
  // switch the appearance and where to go depending on the conditions.
  let routeAt = routes?.[0]
  if(routeAt?.name === 'Main' && routeAt?.state?.routes){
    routeAt = routeAt.state.routes[0]
  }

  // Don't show back button if it's root for memberships login
  const isLimitedToken = jwt.isLimitedToken(token)
  if(isLimitedToken){
    const parsed = jwt.parse(token)
    const groupId = parsed?.authorizable_id
    if(routeAt.name === 'Group' && routeAt.params.id === groupId){
      return <RootIcon />
    }
  }

  // make it root Icon to show it as 'Home' screen to user.
  // createNativeStackNavigator will return Main as part of route so handle this carefully
  if(routeAt?.name === 'Main' || rootRoute.name === routeAt?.name){
    return <RootIcon />
  }

  // NewBooking has nested route, so can go back to good from params
  if(routeAt?.name === 'NewBooking' || routeAt?.name === 'NewBookingNow' || routeAt?.name === 'GoodComments'){
    rootRoute = { name: 'Good', params: { id: routeAt?.params?.goodId || routeAt?.params?.id } }
  }

  return(
    <HeaderBackButton
      onPress={()=>{
        navigation.reset({
          index: 0,
          routes: [ rootRoute ]
        })
      }}
    />
  )

}

export default BackButton
