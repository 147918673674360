import React from 'react'
import { PropTypes } from 'prop-types'
import Icon from './Icon'
import TouchableOpacity from 'components/TouchableOpacity'
import { styles } from 'styles'

const IconButton = props => {

  const theme = 'light'

  let {
    color = styles.color[theme].primary,
  } = props

  const {
    name,
    onPress,
    transparent,
    inverted,
    iconContainerStyle,
    containerStyle,
    size='md',
    label,
    dark,
    regular,
    nativeID,
    disabled,
  } = props

  if(transparent) color = 'rgba(0,0,0,0)'

  const buttonSize = styles.avatarSize[size]

  const iconSize = buttonSize * 0.55

  let iconColor = styles.color[theme].primary
  if(inverted) iconColor = '#fff'
  if(label) iconColor = styles.color.grey
  if(dark) iconColor = styles.color.onBackground
  if(disabled) iconColor = styles.color.grey

  return (
    <TouchableOpacity
      nativeID={nativeID}
      onPress={disabled ? ()=> {} : onPress}
      activeOpacity={0.6}
      style={{
        width: buttonSize,
        height: buttonSize,
        backgroundColor: color,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: buttonSize / 2,
        ...containerStyle,
      }}
    >
      <Icon
        name={name}
        size={iconSize}
        color={iconColor}
        containerStyle={iconContainerStyle}
        regular={regular}
      />
    </TouchableOpacity>
  )

}

IconButton.propTypes = {
  name: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  size: PropTypes.string,
  transparent: PropTypes.bool,
  inverted: PropTypes.bool,
  iconContainerStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  color: PropTypes.string,
  label: PropTypes.bool,
  regular: PropTypes.bool,
}

export default IconButton
