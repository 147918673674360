import { supabase } from '@/features/supabase'

const limitedStack = (stack: string) => {
  const lines = stack.split('\n')
  const limited = lines.slice(0, 10)
  return limited.join('\n')
}

const filers = [
  'password',
  'Authorization',
  'passcode',
  'token',
  'access_token',
  'email',
  'name',
]
// it takes any object and returns a new object with sensitive information removed
type AnyObject = Record<string, any>
const safeParams = (params: AnyObject) => {
  if(!params) return params
  if(typeof params !== 'object') return params

  for(const key in params){
    if(filers.includes(key)) params[key] = '********'
    if(typeof params[key] === 'object'){
      params[key] = safeParams(params[key])
    }
  }
  return params
}

const renderExtra = (extra: any) => {
  if(!extra) return ''
  // blank object
  if(Object.keys(extra).length === 0) return ''

  const safe = safeParams(extra)

  const string =`\`\`\`
${JSON.stringify(safe)}
\`\`\``
  return string
}

const ingnoredErrors = [
  'Network request failed',
  'status: 401',
  'status: 404',
  'リクエストがタイムアウトになりました。',
  'token expired',
  'Fetching the token failed'
]

const isIgnored = (message: string): boolean => {
  let result = false
  ingnoredErrors.forEach((error) => {
    if(message.includes(error)) result = true
  })
  return result
}

const webhook = 'https://jsnmxmixdopvswytbduj.functions.supabase.co/functions/v1/webhook'
export const capture = async(error: any, extra: any) => {
  if(__DEV__) return

  try{
    let content = ''
    if(error?.message){
      if(isIgnored(error.message)) return

      if(extra?.componentStack) delete extra.componentStack
      content = `**${error.message}**
\`\`\`
${limitedStack(error.stack)}
\`\`\`
${renderExtra(extra)}`
    }else{
      content = JSON.stringify({ error, extra })
    }
    await supabase.post(webhook, { content })()
  }catch(e: unknown){
    console.error(e)
  }
}
